import React from 'react'
import Socials from './socials'
import CKLogo from '../assets/ck-logo.png'
import AsyncTalkLogo from '../assets/asyncTalk-logo.png'
import SymbolItem from './symbol-item'

type ItemProps = {
  icon: string
  name: string
  desc?: string
  link: string
  hidden?: boolean
}

const projects: ItemProps[] = [{
  icon: CKLogo,
  name: 'ClippingKK',
  link: 'https://clippingkk.annatarhe.com',
  desc: 'Your clippings in Kindle'
}, {
  icon: AsyncTalkLogo,
  name: 'AsyncTalk',
  link: 'https://asynctalk.com',
  desc: 'A podcast committed to take the web development to next level'
}, {
  icon: '🤳',
  name: 'Athena',
  link: 'https://db.annatarhe.com',
  desc: 'TODO...'
// }, {
//   hidden: true,
//   icon: '👧',
//   name: 'fyj',
//   link: 'https://i.fanyijing.love'
}]

function Descriptor() {
  return (
    <section className="flex flex-col">
      <div className="mt-8">
        <h3 className="text-4xl font-light mb-4 text-center md:text-left text-gray-800 dark:text-gray-100">
          Projects
        </h3>
        <ul className='flex justify-between flex-col'>
          {projects.map(p => (
            <SymbolItem {...p} key={p.name} />
          ))}
        </ul>
      </div>

      <Socials />
    </section>
  )
}

export default Descriptor
