import React from 'react'

type SymbolItemProps = {
  icon: React.ReactElement | string
  name: string
  desc?: string
  link: string
  hidden?: boolean
}

function SymbolItem(props: SymbolItemProps) {
  return (
    <li
      className={'hover:bg-indigo-200 p-2 rounded hover:scale-110 transform duration-300 transition-all mr-2 flex' + (props.hidden ? ' hidden' : '')}
    >
      <a href={props.link} target="_blank" className="flex w-full items-center">
        {typeof props.icon === 'string' ? (props.icon.includes('.') ? (
          <img src={props.icon} className='w-12 h-12' />
        ) : (
          <span className="text-2xl h-12 w-12 text-center flex items-center justify-center">
            {props.icon}
          </span>
        )) : props.icon}
        <div className='flex ml-4 flex-col justify-center text-gray-800 dark:text-gray-100'>
          <span className='text-base'>{props.name}</span>
          {props.desc && (
            <span className='text-sm'>{props.desc}</span>
          )}
        </div>
      </a>
    </li>
  )
}

export default SymbolItem
