import React from 'react'
import jikeLogoImage from '../../assets/jike-logo.png'

type JikeIconProps = {
}

function JikeIcon(props: JikeIconProps) {
  return (
    <div>
      <img src={jikeLogoImage} className='w-6 h-6 object-fill rounded' />
    </div>
  )
}

export default JikeIcon
