import React from 'react'
import GithubIcon from './icons/github'
import JikeIcon from './icons/jike'
import StoryblokIcon from './icons/storyblok'
import TwitterIcon from './icons/twitter'
import SymbolItem from './symbol-item'
import { InfoItem } from './types'

type SocialsProps = {
}

function Socials(props: SocialsProps) {
const socalAccounts: InfoItem[] = [{
  // icon: 'J ',
  icon: <JikeIcon />,
  name: '即刻',
  link: 'https://web.okjike.com/u/20965c29-1f92-4df8-9107-45043db29278'
}, {
  icon: <TwitterIcon />,
  name: 'Twitter',
  link: 'https://twitter.com/AnnatarHe'
}, {
  icon: <StoryblokIcon />,
  name: 'Blog',
  link: 'https://AnnatarHe.github.io'
// }, {
//   icon: '📢',
//   name: 'Bilibili',
//   link: 'https://space.bilibili.com/173440264'
}, {
  icon: <GithubIcon />,
  name: 'Github',
  link: 'https://github.com/AnnatarHe'
}]

  return (
      <div className="mt-8 mb-8">
        <h3 className="text-4xl font-light mb-4 text-center md:text-left text-gray-800 dark:text-gray-100">Social Media</h3>
        <ul className="flex flex-wrap flex-rows items-center justify-between mr-8">
          {socalAccounts.map(s => (
            <SymbolItem {...s} key={s.name} />
          ))}
        </ul>
      </div>
  )
}

export default Socials
