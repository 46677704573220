import React, { useEffect, useState } from 'react'

const mainICPLicense = '豫ICP备15003571号'
const mainICPInfo = {
  'cn': mainICPLicense + ' - 1',
  'com': mainICPLicense + ' - 3'
}

function useF2kingICPLicense() {
  const [icpLicenseKey, setICPLicenseKey] = useState('cn')
  useEffect(() => {
    const origin = location.origin
    if (!origin.includes('annatarhe.cn')) {
      return
    }
    setICPLicenseKey('com')
  }, [])
  return (mainICPInfo as any)[icpLicenseKey]
}

function ICP() {
  const myRealAndSecretLover = btoa('https://i.fanyijing.love')
  const f2kingICPLicense = useF2kingICPLicense()
  return (
    <div className=" fixed right-5 w-full text-center text-white bottom-0 mr-2 mb-2 flex flex-col items-center text-xs">
      <a href="http://beian.miit.gov.cn/" target="_blank">{f2kingICPLicense}</a>
    </div>
  )
}

export default ICP
