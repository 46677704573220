import * as React from "react"
import type { HeadFC } from "gatsby"
import Descriptor from "../components/desciptor"
import ICP from "../components/icp"
import UserInfo from "../components/user-info"

const bgs = [
  'https://ck-cdn.annatarhe.cn/lrYm87HbNXn7rU1vBdh4iC7dXUGB8St2/DSCF1642.jpg',
  'https://ck-cdn.annatarhe.cn/k0eLe3mUbrMrYqJw9HPFDmSWIBqmnLpX/DSCF1611.jpg',
  'https://ck-cdn.annatarhe.cn/UmzmeThtEhYvVewG5TkBmS8IEGiAGSwI/DSCF1609.jpg',
  'https://ck-cdn.annatarhe.cn/nwTvnaDfnzGR4axGYCMQ9my0Sq2MGk0c/DSCF1575.jpg',
  'https://ck-cdn.annatarhe.cn/aRHhsYQI9hg6aqDhFvAEiqyi1gJzJnca/DSCF1310.jpg',
  'https://ck-cdn.annatarhe.cn/41me0rLT3nU4pdv9PdSMSNPR2acAvbQ4/DSCF1109.jpg',
  'https://ck-cdn.annatarhe.cn/RhcWMEhdl9h6hJD7kO9UNN8mzIFDKRva/DSCF1069-HDR.jpg',
  // 'DSCF1069-HDR.jpg',
  // 'DSCF1109.jpg',
  // 'DSCF1310.jpg',
  // 'DSCF1575.jpg',
  // 'DSCF1609.jpg',
  // 'DSCF1611.jpg',
  // 'DSCF1642.jpg',
  // 'DSCF1642.jpg',
]

function useBackgroundImage() {
  // const [bgImage, setBgImage] = useState('https://picsum.photos/100/100')
  // useEffect(() => {
  //   const h = document.body.clientHeight
  //   const w = document.body.clientWidth
  //   setBgImage(`https://picsum.photos/${w}/${h}?grayscale`)
  // }, [])
  const [bgImage, setBgImage] = React.useState('')

  React.useEffect(() => {
    const idx = Math.floor(Math.random() * bgs.length)
    const bg = bgs[idx]

    setBgImage(bg)

    // function onImageLoad() {
    //   setImage(bg)
    // }
    // let img = new Image()
    // img.addEventListener('load', onImageLoad)

    // return () => {
    // }
  }, [])

  return bgImage
}

function useCKScript() {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://web-widget-script.pages.dev/bundle.js'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

function IndexPage() {
  // useCKScript()
  const bg = useBackgroundImage()

  return (
    <div
      className='h-screen flex items-center justify-center overflow-x-hidden bg-gradient-to-br object-cover'
      style={{
        backgroundImage: `url(${bg})`
      }}
    >
      <div className=' w-full h-full backdrop-blur-2xl flex items-center justify-center bg-black bg-opacity-40'>

        <main className="container flex flex-col m-auto justify-around md:flex-row">
          <UserInfo />
          <div className='flex flex-col justify-center'>

            {/* <CKSnippet /> */}
            <Descriptor />
          </div>
        </main>
        <ICP />
      </div>
    </div>
  )
}

export default IndexPage

export const Head: HeadFC = () => (
  <>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <meta name="description" content="AnnatarHe's Home Page" />
    <meta name="keywords" content="AnnatarHe" />
    <meta name="author" content="AnnatarHe, annatar.he+home@gmail.com" />
    <title>AnnatarHe</title>

    <meta name="theme-color" content="#93c5fd" media="(prefers-color-scheme: light)" />
    <meta name="theme-color" content="#93c5fd" media="(prefers-color-scheme: dark)" />
    <meta property="og:url" content="https://annatarhe.com" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="AnnatarHe" />
    <meta property="og:image" content={require('../assets/avatar.jpg').default} />
    <meta property="og:description" content="AnnatarHe's home page" />
    <meta property="og:site_name" content="AnnatarHe" />
    <meta property="article:author" content="AnntarHe" />
    <meta name="description" content="AnnatarHe's home page" />
    <meta name="keyword" content="AnnatarHe" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@AnnatarHe" />
    <meta name="twitter:creator" content="@AnnatarHe" />
    <meta name="twitter:url" content="https://annatarhe.com" />
    <meta name="twitter:title" content="AnnatarHe" />
    <meta name="twitter:description" content="AnnatarHe's home page" />
    <meta name="twitter:image" content={require('../assets/avatar.jpg').default} />
    <meta name="twitter:image:alt" content="AnnatarHe" />
    <meta name="next-head-count" content="4" />
    <link rel="icon" href="./favicon.ico" />
  </>
)
