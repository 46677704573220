import React from 'react'

const imageDefaultStyle = {
  maxWidth: 'calc(100vw - 1rem'
}

function UserInfo() {
  return (
    <div className='flex justify-center items-center flex-col mb-4'>
      <img src="https://avatars3.githubusercontent.com/u/8704175?v=4&s=460" alt="AnnatarHe's avatar" className="w-32 h-32 p-4 rounded-full" />
      <h1 className="text-3xl text-center text-transparent bg-clip-text bg-gradient-to-br from-orange-300 to-teal-400 font-extrabold ">AnnatarHe</h1>
      <div className="text-lg text-gray-800 mb-8 text-center dark:text-gray-200">
        <p>😄 当然，颜值吃饭的偶像派程序员</p>
        <p>📖 如果你用 kindle 可以考虑试试 clippingkk 整理书摘</p>
        <p>🔊 AsyncTalk 期望我们可以把 web 开发带往下一个高度</p>
      </div>

      <div className='flex flex-wrap items-center justify-center flex-col'>
        <img
          src="https://github-readme-stats.vercel.app/api?username=AnnatarHe&show_icons=true"
          alt="annatarhe"
          className='m-2'
          style={imageDefaultStyle}
        />
        {/* <img src="https://github-readme-stats.vercel.app/api/top-langs/?username=AnnatarHe&layout=compact" alt="top-langs" className='w-full' /> */}
        <img
          src="https://github-readme-stats.vercel.app/api/wakatime?username=AnnatarHe"
          alt=""
          className='m-2'
          style={imageDefaultStyle}
        />
      </div>

      <del className='text-xs text-gray-400 dark:text-gray-100' >
        Shanghai, China (temporarily)
      </del>
      <address className="text-sm text-gray-800 dark:text-gray-200">
        Singapore
      </address>
      <a
        className="text-medium text-gray-800 dark:text-gray-200"
        href='mailto:annatar.he+home@gmail.com'
      >Annatar.He+home@gmail.com</a>
    </div>
  )
}

export default UserInfo
